<!--
 * @version: 1.0
 * @Date: 2023-04-23 14:22:23
 * @LastEditors: chenxu chenxu@libawall.cn
-->
<template>
  <div class="branch">
    <div class="condition" v-for="(item, index) in groups" :key="item.id">
      <!-- 头部 -->
      <div class="condition-header">
        {{ index === 0 ? '当' : '且' }}
        <i class="iconfont icon-delete icon-del" @click="delBranch(item.title, index)" v-if="!allDisabled"></i>
      </div>
      <!-- 下拉框 -->
      <div class="con-item">
        <!-- 条件类型 -->
        <a-select
          v-model:value="item.title"
          style="width:100%"
          @change="value => selChange(value, index)"
          @dropdownVisibleChange="open => dropdownVisibleChange(open, item.title)"
          :disabled="allDisabled"
        >
          <a-select-option v-for="el in selectList" :value="el.title" :key="el.value">{{ el.title }} </a-select-option>
        </a-select>
        <!-- 判断条件 -->
        <a-select
          v-if="item.title !== '发起人'"
          :value="item.compare"
          style="width:100%; margin: 12px 0px"
          @select="selectCompare($event, index)"
          :disabled="allDisabled"
        >
          <a-select-option :value="deter.value" v-for="deter in determineList" :key="deter.value">{{
            deter.title
          }}</a-select-option>
        </a-select>
        <!-- 部门或成员 -->
        <div
          v-if="item.title === '发起人'"
          :class="['select', allDisabled && 'btn-disabled']"
          @click="selectPerson(index)"
        >
          <div class="pt-tag" v-for="(el, ind) in valueList" :key="el.dataId">
            <div class="pt-name" :title="el.dataName">
              {{ el.dataName }}
            </div>
            <CloseOutlined @click.stop="deleteSta(ind, 3)" class="pt-close" v-if="!allDisabled" />
          </div>
          <div class="select-no" v-if="valueList?.length <= 0">
            请选择部门或人员
          </div>
        </div>
        <!-- 金额/数字 -->
        <div class="num" v-else>
          <a-input
            placeholder="请输入数字"
            :style="{
              width:
                item.compare === 'ABA' || item.compare === 'B' || item.compare === 'BA' || item.compare === 'AB'
                  ? item.inputWidth
                  : '100%'
            }"
            v-model:value="item.value[0]"
            :disabled="allDisabled"
            @blur="value => blurNum(value, index, 0)"
          />
          <template
            v-if="item.compare === 'ABA' || item.compare === 'B' || item.compare === 'BA' || item.compare === 'AB'"
          >
            <a-select
              :disabled="allDisabled"
              class="num-item"
              v-model:value="item.betDetermine"
              @select="value => selectBet(value, index)"
            >
              <a-select-option v-for="det in symbolList" :value="det.title" :key="det.value">{{
                det.title
              }}</a-select-option>
            </a-select>
            <div>{{ item.title }}</div>
            <a-select
              :disabled="allDisabled"
              class="num-item"
              v-model:value="item.branDetermine"
              @select="value => selectBet(value, index)"
            >
              <a-select-option v-for="symbol in symbolList" :value="symbol.title" :key="symbol.value">{{
                symbol.title
              }}</a-select-option>
            </a-select>
            <a-input
              placeholder="请输入数字"
              :disabled="allDisabled"
              :style="{ width: item.inputWidth }"
              v-model:value="item.value[1]"
              @blur="value => blurNum(value, index, 1)"
            />
          </template>
        </div>
      </div>
      <!-- TODO：统一选择成员 -->
      <a-modal
        centered
        title="添加成员"
        width="655px"
        class="global-range-modal"
        :visible="RangeModalVisible"
        @cancel="handleCancel"
        @ok="handleOk"
      >
        <GlobalSelector
          :visible="RangeModalVisible"
          :selectedList="valueList"
          :selectorInfo="selectorInfo"
          :renderList="renderList"
          @getSearchInfo="getSearchInfo"
          @toNext="toNext"
          @changeData="changeData"
          ref="globalSelector"
        >
        </GlobalSelector>
      </a-modal>
    </div>
    <!-- 添加按钮 -->
    <div class="add-branch" @click="addBranch" v-if="groups.length < conditSelect.length && !allDisabled">
      <PlusOutlined />
      添加条件
    </div>
  </div>
</template>

<script>
import { PlusOutlined, CloseOutlined, IdcardFilled } from '@ant-design/icons-vue'
import { isTemplateElement } from '@babel/types'
import GlobalSelector from '@/components/GlobalSelector/index.vue'
import { getDepartmentOrStaff } from '@/apis/businessManage'
import { formateListData } from '@/utils/selectorListFormate.js'

export default {
  name: 'BranchConfig',
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showConfig: {
      type: Boolean,
      defaulte: false
    },
    isCancel: {
      type: Boolean,
      defaulte: false
    }
  },

  components: {
    PlusOutlined,
    CloseOutlined,
    GlobalSelector
  },
  data() {
    return {
      index: 0, // 第一个条件
      RangeModalVisible: false,
      determineList: [
        { value: '<', title: '小于', key: '<' },
        { value: '<=', title: '小于等于', key: '<=' },
        { value: '>', title: '大于', key: '>' },
        { value: '>=', title: '大于等于', key: '>=' },
        { value: '=', title: '等于', key: '=' },
        { value: 'B', title: '介于（两个数之间）', key: 'B' }
      ],
      symbolList: [
        { value: '小于', title: '<' },
        { value: '小于等于', title: '≤' }
      ],
      branNum: '', // 数字1
      betNum: '', // 数字2
      valueList: [],
      groups: [],
      selectList: [{ value: '1', title: '发起人' }],
      selectorInfo: {
        placeholder: '请输入员工姓名或部门',
        isSelectDept: true,
        abelCheckAll: true,
        ableSelectDep: true,
        ableChangeData: true
      },
      renderList: [],
      selectorType: 1
    }
  },
  watch: {
    showConfig: {
      deep: true,
      handler(value) {
        // console.log('showConfig', value)
        // console.log('isCancel', this.isCancel)
        // console.log(this.config)
        if (value) {
          if (this.isCancel) return
          this.groups = []
          const groupsList = JSON.parse(JSON.stringify(this.config.groups[0].conditions))
          groupsList.forEach(item => {
            if (item.title === '发起人') {
              item.value.forEach(el => {
                el.dataType = el.dataType === 'user' ? 1 : 2
              })
              this.valueList = item.value
            }
            const betM = ['B', 'BA'].includes(item.compare) ? '<' : '≤'
            const branM = ['B', 'AB'].includes(item.compare) ? '<' : '≤'
            console.log(item)
            let inputW = 106 - item.title.length * 8
            this.groups.push({
              id: item.id,
              title: item.title,
              valueType: item.valueType,
              compare: ['B', 'AB', 'BA', 'ABA'].includes(item.compare) ? 'B' : item.compare,
              value: item.value,
              betDetermine: item.betDetermine || betM,
              branDetermine: item.branDetermine || branM,
              inputWidth: inputW <= 66 ? '66px' : inputW + 'px'
            })
            console.log(this.groups)
          })
        } else {
          // 没点保存
          if (!this.isCancel) {
            // console.log('没点保存')
          } else {
            console.log('点了保存', this.groups)
            // debugger
            this.config.groups[0].cids = []
            this.config.groups[0].conditions = this.groups.map(item => {
              if (item.title === '发起人') {
                item.value = this.valueList
                item.value.forEach(el => {
                  el.dataType = el.dataType === 1 ? 'user' : 'dept'
                })
              }
              if (item.valueType == 'Number') {
              }
              this.config.groups[0].cids.push(item.id)

              const betDe = item.betDetermine
              const branDe = item.branDetermine
              let compare = item.compare
              if (['B', 'AB', 'BA', 'ABA'].includes(compare)) {
                switch (true) {
                  case betDe === '<' && branDe === '<':
                    compare = 'B'
                    break
                  case betDe === '≤' && branDe === '<':
                    compare = 'AB'
                    break
                  case betDe === '<' && branDe === '≤':
                    compare = 'BA'
                    break
                  default:
                    compare = 'ABA'
                }
              }
              console.log('compare', compare, betDe, branDe)
              return { ...item, compare }
            })
            // this.config.groups[0].conditions = this.groups
            if (this.groups.length > 1) {
              this.config.groups[0].groupType = 'AND'
            }
            console.log(8888, this.groups)
            console.log(this.config.groups[0])
          }
        }
      }
    }
  },
  created() {
    this.getScopeList()
    // console.log('条件抽屉', this.config)
    this.groups = []
    const groupsList = JSON.parse(JSON.stringify(this.config.groups[0].conditions))
    groupsList.forEach(item => {
      console.log('76473', item)
      let inputW = 106 - item.title.length * 8
      this.groups.push({
        id: item.id,
        title: item.title,
        valueType: item.valueType,
        compare: ['B', 'AB', 'BA', 'ABA'].includes(item.compare) ? 'B' : item.compare,
        value: item.value,
        betDetermine: ['B', 'BA'].includes(item.compare) ? '<' : '≤',
        branDetermine: ['B', 'AB'].includes(item.compare) ? '<' : '≤',
        inputWidth: inputW <= 66 ? '66px' : inputW + 'px'
      })
      if (item.title === '发起人') {
        item.value.forEach(el => {
          el.dataType = el.dataType === 'user' ? 1 : 2
        })
        this.valueList = item.value
      }
    })
  },
  computed: {
    // 条件， 根据表单来是否有数字和金额
    conditSelect() {
      const formItems = this.$store.state.process.design.formItems
      const list = [{ value: '1', title: '发起人', id: 'root' }]
      formItems?.forEach(item => {
        // console.log('条件选项',item);
        if (!item.props.required) return
        if (item.configName === 'NumberInput' || item.configName === 'AmountInput') {
          list.push({ value: `${list.length + 1}`, title: item.title, id: item.id })
        }
      })
      console.log(list)
      return list
    },
    formItems() {
      return this.$store.state.process.design.formItems
    },
    allDisabled() {
      return this.$store.state.process.isPreview
    }
  },
  methods: {
    // 添加条件
    addBranch() {
      // 找到groups没有conditSelect的
      let temp = []
      let nowObj = []
      this.groups.forEach(element => {
        temp.push(element.title)
      })
      for (let one of this.conditSelect) {
        if (temp.indexOf(one.title) === -1) {
          nowObj.push(one)
        }
      }

      // 需要找到对应的控件的id
      const titlObj = nowObj[0]
      console.log(nowObj)
      const formItems = this.$store.state.process.design.formItems
      console.log('瞅瞅store里面有什么', this.$store.state)
      // const idObj = formItems.find(item => item.id === titlObj.id)
      // console.log(idObj)
      // this.valueList = []
      this.groups.push({
        id: titlObj.title === '发起人' ? 'root' : idObj.id,
        title: titlObj.title, // 条件 1发起人 2金额 3数值
        valueType: titlObj.title === '发起人' ? 'User' : 'Number',
        compare: titlObj.title === '发起人' ? '' : '<', // 符号
        value: [] // 人或数字
      })
    },

    // 数字输入框失焦
    blurNum(e, index, ind) {
      // this.index = index
      let result = e.target.value.replace(/[。，]/g, '.') // 句号转成小数点
      result = result.replace(/[^\d.]/g, '') // 清除非数字和小数点的字符
      result = result.replace(/^\./, '0.') // 第一个字符如果是小数点，则自动添加0
      result = result
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.') // 只保留第一个小数点
      result = result.replace(/^0*(?=\d)/, '') // 开头不允许输入0
      this.groups[index].value[ind] = result
      // console.log(result)
    },

    // 符号下拉框
    selectCompare(value, index) {
      // console.log(value, this.groups[index].compare)
      if (value === this.groups[index].compare) return
      // 介于
      this.groups[index].betDetermine = '<'
      this.groups[index].branDetermine = '<'
      this.groups[index].compare = value
      this.groups[index].value = []
      console.log(this.groups[index])
      const inputW = 106 - this.groups[index].title.length * 8
      this.groups[index].inputWidth = inputW <= 66 ? '66px' : inputW + 'px'
      // console.log('这是宽度',this.inputWidth);
    },

    // 介于下拉框
    selectBet(value, index) {
      console.log(value, index)
      // this.index = index
      const betDe = this.groups[index].betDetermine
      const branDe = this.groups[index].branDetermine
      let compare = ''
      console.log('9999999999', betDe, branDe)
      if (betDe === '<' && branDe === '<') {
        // this.determineList[5].key = 'B'
        compare = 'B'
      } else if (betDe === '≤' && branDe === '<') {
        compare = 'AB'
        // this.determineList[5].key = 'AB'
      } else if (betDe === '<' && branDe === '≤') {
        compare = 'BA'
        // this.determineList[5].key = 'BA'
      } else {
        compare = 'ABA'
        // this.determineList[5].key = 'ABA'
      }
      console.log(this.groups)
      // this.groups[index].compare = compare
    },

    // 随机数字
    getRandomId() {
      return `node_${new Date()
        .getTime()
        .toString()
        .substring(5)}${Math.round(Math.random() * 9000 + 1000)}`
    },

    // 选择弹窗
    selectPerson(index) {
      // console.log('选择成员')
      if (this.allDisabled) return
      this.index = index
      this.RangeModalVisible = true
    },
    // 条件类型下拉框
    selChange(value, index) {
      console.log(value, index)
      console.log(9992, this.groups[index])
      this.groups[index].value = []
      this.groups[index].compare = '<'
      this.groups[index].betDetermine = '<'
      this.groups[index].branDetermine = '<'
      this.groups[index].valueType = value === '发起人' ? 'User' : 'Number'
      // id
      const formItems = this.$store.state.process.design.formItems
      const idObj = this.conditSelect.find(item => item.title === value)
      this.groups[index].id = value === '发起人' ? 'root' : idObj.id
      if (value === '发起人') {
        this.valueList = []
      }
    },

    // 展开下拉框 改变this.selectList
    dropdownVisibleChange(open, value) {
      const nowObj = this.conditSelect.filter(item => item.title === value)
      console.log('当前选中', nowObj, value)
      if (open) {
        this.selectList = []
        if (this.groups.length === 1) {
          this.selectList = this.conditSelect
        } else {
          if (this.groups.length !== this.conditSelect.length) {
            // 找到除了groups里没有的
            let temp = []
            this.groups.forEach(element => {
              temp.push(element.title)
            })
            for (let one of this.conditSelect) {
              console.log(one)
              if (temp.indexOf(one.title) === -1) {
                nowObj.push(one)
              }
            }
          }
          this.selectList = nowObj
        }
      }
    },

    // 发起人删除
    deleteSta(index) {
      console.log(index)
      this.valueList.splice(index, 1)
      // this.groups[this.index].value.splice(index, 1)
    },

    // 删除条件
    delBranch(value, index) {
      console.log(1111, value, index)
      this.groups.splice(index, 1)
      this.config.groups[0].cids.splice(index, 1)
      const selObj = this.conditSelect.find(item => item.title === value)
      console.log(selObj)
      if (selObj.title === '发起人') {
        this.valueList = []
      }
      console.log('----', this.conditSelect)
    },
    //搜索
    getSearchInfo(e) {
      let data = {
        searchName: e
      }
      this.getScopeList(data)
    },
    //去下级
    toNext(id) {
      let data = {
        departmentId: id
      }
      this.getScopeList(data)
    },
    //弹窗取消事件
    handleCancel() {
      this.RangeModalVisible = false
      this.$refs.globalSelector[this.index].cancelEvent()
    },
    //弹窗确定事件
    handleOk() {
      let arr = [...this.$refs.globalSelector[this.index].okEvent()]
      this.valueList = []
      arr.forEach(item => {
        this.valueList.push({
          dataId: item.dataId,
          dataName: item.dataName,
          dataType: item.dataType,
          sex: false,
          selected: false,
          icon: item?.icon,
          mobile: item?.mobile
        })
      })
      this.handleCancel()
    },
    //弹窗数据
    async getScopeList(data) {
      let params = {
        selectorType: this.selectorType, //1部门人员2部门3员工
        allData: true
      }
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        console.log('全部数据', res)
        this.handelData(res)
      }
    },
    // 处理左侧数据
    handelData(res) {
      this.renderList = formateListData(res)
    },
    // 切换数据
    changeData({ isOrganizationData, searchValue }) {
      console.log('切换的数据的类型', isOrganizationData)
      if (isOrganizationData) {
        // 组织架构数据
        this.selectorType = 1
      } else {
        //人员数据
        this.selectorType = 3
      }
      this.getSearchInfo(searchValue)
    }
  }
}
</script>

<style scoped lang="less">
.branch {
  font-size: 14px;
  color: #333;
  .condition {
    border: 1px solid #f0f0f0;
    margin-bottom: 12px;

    .condition-header {
      padding: 10px 16px;
      background-color: #f0f0f0;
      display: flex;
      justify-content: space-between;
      .icon-del {
        cursor: pointer;
        color: #999999;
      }
    }
  }
  .con-item {
    padding: 16px;
    .select {
      border: 1px solid #d9d9d9;
      margin-top: 8px;
      padding: 6px 12px 0px;
      border-radius: 2px;
      color: rgba(0, 0, 0, 0.25);
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      .pt-tag {
        z-index: 10;
        background: #fafafa;
        border: 1px solid #d9d9d9;
        padding: 0px 5px;
        font-size: 12px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        margin-right: 10px;
        margin-bottom: 6px;
      }
      .pt-name {
        color: rgba(0, 0, 0, 0.85);
        cursor: default;
      }
      .pt-close {
        font-size: 10px;
        color: #969799;
        margin: auto 2px;
        &:hover {
          color: #333;
        }
      }
    }
    .num {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      gap: 8px;
      // margin-top: 12px;
      .num-item {
        width: 56px;
        height: 30px;
        animation: none;
      }
    }

    .btn-disabled {
      background-color: #f5f5f5;

      cursor: no-drop;
      .pt-name {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .add-branch {
    cursor: pointer;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    text-align: center;
    margin-top: 12px;
    color: #333333;
    padding: 5px 0px;
  }
}
</style>
